/* Base styles for the portfolio section */
.portfolio {
  min-height: 53.75rem;
  padding-bottom: 10rem;

}

.portfolio-content {
  height: 100%;
}

.portfolios {
  margin-top: 4.05rem;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.portfolio-card {
  display: flex;
  flex-direction: column;
  width: 19.3125rem;
  height: 38.0625rem;
  border-radius: 0.5rem;
  border-bottom: 4px solid var(--primaryColor);
  box-shadow: 0px 0px 25px 15px rgba(0, 0, 0, 0.08);
  padding: 0 1.62rem;
  justify-content: center;
}

.portfolio-card-dark {
  background-color: #1b1b1b;
  border-bottom: 4px solid var(--primaryColor);
  box-shadow: 0 0 15px -5px var(--primaryColor);
  border-right: 1px solid var(--primaryColor);
  border-top: 1px solid var(--primaryColor);
  border-left: 1px solid var(--primaryColor);
}

.image-container {
  width: 16.05506rem;
  height: 8.75rem;
  margin-top: 0;
  object-fit: fill;
}

.portfolio-card img {
  width: 100%;
}

.portfolio-card h3 {
  color: var(--headerText);
  margin-top: 2.5rem;
}

.portfolio-card h4 {
  margin-top: 0.25rem;
  color: var(--headerText);
}

.portfolio-card p {
  width: 15.8125rem;
  margin: 1.75rem 0 3.62rem;
  color: var(--bodyText);
}

/* Media query for screens with a max width of 1024px */
@media screen and (max-width: 1024px) {
  .portfolios {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
  }

  .portfolio-card {
    width: 100%;
    height: auto;
    padding: 1.5rem;
  }

  .image-container {
    align-self: center;
 
    width: 100%;
    height: auto;
  }

  .portfolio-card img {
    height: auto;
  }

  .portfolio-card p {
    width: 100%;
  }

  .portfolio-card:nth-child(1) {
    grid-column: span 2;
  }

  .portfolio-card:nth-child(1) .image-container{
    width: 90%;
  }
}

/* Additional media query for even smaller screens */
@media screen and (max-width: 568px) {
  .portfolios {
    grid-template-columns: 1fr;
  }

  .portfolio-card:nth-child(1) {
    grid-column: span 1;
  }

  .portfolio-card:nth-child(1) .image-container{
    width: 100%;
  }
}
