.what-we-offer {
  min-height: 37.390625rem;
  padding: 5rem 0 10rem;
}

.what-we-offer-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.what-we-offer-content p {
  color: var(--bodyText);
}

.description {
  text-align: center;
  max-width: 35.0625rem;
  margin: 2.05rem 0 5.87rem;
}

.cards {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.card {
  width: 17.5rem;
  height: 14.8125rem;
  text-align: center;
}

.card img,
.card h3 {
  white-space: nowrap;
  margin-bottom: 1rem;
  color: var(--headerText);
}

@media screen and (max-width: 1024px) {
  .cards {
    flex-direction: column;
    align-items: center;
    gap: 5rem;
  }


}
