.footer {
  position: relative;
  height: 100%;
  width: 100%;
  margin-top: 0;
  z-index: 1;
}

.footer-container {
  position: relative;
  height: 29.375rem;
  display: flex;
  justify-content: center;
}

.background-container {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 80rem;
  width: 100%;
  z-index: -1;
  overflow-x: auto;
  background: var(--backgroundAssetOne);
}

.background-container::-webkit-scrollbar {
  display: none;
}

.footer-content {
  height: 100%;
  display: flex;
  padding: 0 3%;
}

.footer-content-left {
  display: flex;
  justify-content: space-around;
  margin-top: 7.06rem;
  flex: 1;
}

.toggle-content-container {
  position: relative;
  width: 5rem;
}

.toggle-content {
  position: absolute;
  display: flex;
  top: -335px;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.light {
  z-index: -1;
  height: auto;
  width: auto;
  position: relative;
  top: 0;
}

.wire {
  height: 330px;
  width: 5px;
  -webkit-transform: translateY(50x);
  -ms-transform: translateY(50x);
  transform: translateY(50x);
  background: var(--secondaryColor);
  margin: auto;
  z-index: 9;
}

.wire-dark {
  background: var(--primaryColorGreen100);
}

.triangle {
  border-left: 26px solid transparent;
  border-right: 26px solid transparent;
  border-bottom: 26px solid var(--secondaryColor);
  -webkit-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  transform: translateY(-15px);
}
.triangle-dark {
  border-bottom: 26px solid var(--primaryColorGreen100);
}

.bulb {
  background: var(--secondaryColor);
  height: 20px;
  width: 20px;
  position: relative;
  z-index: -100;
  border-radius: 50%;
  margin: auto;
  position: absolute;
  bottom: 6px;
  right: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bulb:before {
  display: none;
  position: absolute;
  border-radius: 50%;
  content: "";
  height: 79px;
  width: 79px;
  opacity: 0.7;
  animation-name: bulbFlicker;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-timing-function: step-start;
  transform: translateY(-22px);
  background: var(--primaryColor);
  background: -o-radial-gradient(
    circle,
    var(--primaryColor) 0,
    rgba(0, 0, 0, 0) 45%
  );
  background: radial-gradient(
    circle,
    var(--primaryColor) 0,
    rgba(0, 0, 0, 0) 45%
  );
}

.bulb-animated:before {
  display: block;
}

@keyframes bulbFlicker {
  0%,
  2%,
  9%,
  26%,
  48% {
    background: var(--primaryColor);
    background: -o-radial-gradient(
      circle,
      var(--primaryColor) 0,
      rgba(0, 0, 0, 0) 45%
    );
    background: radial-gradient(
      circle,
      var(--primaryColor) 0,
      rgba(0, 0, 0, 0) 45%
    );
  }

  1%,
  8%,
  25%,
  47% {
    background: rgba(67, 255, 167, 0);
  }

  100% {
    background: var(--primaryColor);
    background: -o-radial-gradient(
      circle,
      var(--primaryColor) 0,
      rgba(0, 0, 0, 0) 45%
    );
    background: radial-gradient(
      circle,
      var(--primaryColor) 0,
      rgba(0, 0, 0, 0) 45%
    );
  }
}

.toggle-content p {
  font-size: 0.7rem;
  text-align: center;
  opacity: 0.8;
  color: var(--secondaryColor);
}

.footer-content-left .logo-container {
  display: flex;
  flex-direction: column;
  min-height: 15.25rem;
}

.footer-content-left .logo {
  width: 9.94488rem;
  height: 5.385rem;
  margin-bottom: 2rem;
  position: relative;
}

.after-dark {
  position: absolute;
  display: block;
  transition: 0.1s ease-in;
  font-family: Yellowtail, sans-serif;
  color: var(--primaryColor);
  top: 47px;
  left: 70px;
  z-index: 1000;
  transform: rotate(-13deg);
}

.after-dark span {
  font-family: Yellowtail;
  display: table-cell;
  margin: 0;
  font-size: 1.5em;
  font-size: 2.25rem;
  padding: 0;
  animation: neonGlow 3s linear infinite;
}

.after-dark span:nth-child(1) {
  animation-delay: 1s;
}

.after-dark span:nth-child(2) {
  animation-delay: 1s;
}

.after-dark span:nth-child(3) {
  animation-delay: 1.2s;
}

.after-dark span:nth-child(4) {
  animation-delay: 1s;
}

.after-dark span:nth-child(5) {
  animation-delay: 2s;
  margin-right: 3px !important;
}

.after-dark span:nth-child(6) {
  animation-delay: 2.45s;
}

.after-dark span:nth-child(7) {
  animation-delay: 2.75s;
}

.after-dark span:nth-child(8) {
  animation-delay: 2.75s;
}

.after-dark span:nth-child(9) {
  animation-delay: 2.75s;
}

@keyframes neonGlow {
  0%,
  100% {
    color: #abfecb;
    filter: blur(2px);
    text-shadow: 0 0 10px var(--primaryColor), 0 0 20px var(--primaryColor),
      0 0 40px var(--primaryColor), 0 0 50px var(--primaryColor),
      0 0 60px var(--primaryColor);
  }

  5%,
  95% {
    color: #fff;
    filter: blur(0);
    text-shadow: 0 0 10px var(--primaryColor), 0 0 20px var(--primaryColor),
      0 0 40px var(--primaryColor), 0 0 50px var(--primaryColor),
      0 0 60px var(--primaryColor);
  }
}

.logo-contact-details {
  display: flex;
  flex-direction: column;
  gap: 0.87rem;
}

.logo-contact-details a {
  color: var(--secondaryColor);
  line-height: 170%;
}

.footer-content-right {
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.footer-content-right h2 {
  color: var(--secondaryColor);
  font-size: 1.5rem;
  font-weight: 600;
}

.footer-content-right .ruler {
  width: 10.9375rem;
  height: 0.0625rem;
  background: var(--secondaryColor);
  margin: 0.44rem 0 1rem;
}

.footer-content-right ul {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.footer-content-right li {
  color: var(--secondaryColor);
  white-space: nowrap;
}

.copyright {
  background: var(--backgroundAssetTwo);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
}

.copyright p {
  color: var(--secondaryColor);
  line-height: 1.25rem;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .toggle-content-container {
    position: absolute;
    right: 2.5rem;
    top: 12.5rem;
  }

  .footer-container {
    height: fit-content;
  }

  .background-container {
    overflow: auto;
    min-height: 100rem;
  }

  .background-container::-webkit-scrollbar {
    display: none;
  }

  .footer-content {
    flex-direction: column;
    height: auto;
  }
  .footer-content-left {
    flex-direction: column;
    margin-top: 0;
  }

  .logo-contact-details {
    margin: 5rem 0;
  }

  .footer-content-right {
    flex-direction: column;
    align-items: start;
    gap: 3rem;
    margin-bottom: 5rem;
  }
}
