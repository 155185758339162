.section-title{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page-ruler {
  margin-top: 0.5rem;
    display: flex;
    align-items: center;
    gap: 1.25rem;
    justify-content: center;
  }
  
  .ruler {
    width: 5rem;
    border: 0.5px dashed var(--primaryColor);
  }
  
  .image-container {
    width: 0.88938rem;
    height: 1.77876rem;
  }
  
  .image-container img {
    width: 100%;
    height: 100%;
  }
  
  .section-title h2 {
    color: var(--primaryColor);
    margin-bottom: 0.25rem;
    white-space: nowrap;
  }
  
  .section-title span {
    color: var(--tertiaryColor);
  }
  