.page-header{
  width: 100%;
  height: 25rem;
  background: linear-gradient(
    301deg,
    var(--primaryColor) 34.39%,
    var(--primaryColorGreen200) 100%
  );
}

.page-header-dark{
  background: linear-gradient(
    360deg,
    var(--primaryColorGreen100) 34.39%,
    var(--primaryColorGreen200) 100%
  );
}

.background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 1; 
}

.page-header-content{
  display: flex;
align-items: center;
height: 100%;
justify-content: center;
}

.page-header-content h1 {
  color: var(--primaryColorGreen900);
  margin-top: 127PX;
}

@media screen and (max-width: 1024px) {
  .page-header{
    overflow: hidden;
    height: 13.75rem;
  }
}