.contact-form {
  height: auto;
  width: 100%;
  overflow: visible;

}

.contact-form-content {
  display: flex;
  width: 100%;
  gap: 3rem;
  padding: 0 2rem;
  justify-content: center;
  
}

.left {
  width: 100%;
  max-width: 31.25rem;
  width: 100%;
  position: relative;
}

.contact-background-image {
  top: -2rem;
  left: -5rem;
  position: absolute;
  z-index: -1;
  width: 100%;
  max-width: 29.3rem;
  height: 31.15rem;
  flex-shrink: 0;
  
}


.form-contact {
  margin: 1rem;
  max-width: 32rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 45.5875rem;
  padding: 1.5rem;
  background-color: var(--primaryBackgroundColor);
  border-radius: 0.3125rem;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
}

.form-contact-dark {
  box-shadow: 0 0 25px -5px var(--primaryColor);
  border: 1px solid var(--primaryColor);
  background-color: var(--primaryBackgroundColor);
  background: linear-gradient(#333, var(--primaryColorBlack));
}

.form-contact h2 {
  color: var(--primaryColor);
  font-size: 1.75rem;
  margin-bottom: 1.5rem;
}
.form-contact span {
  color: var(--tertiaryColor);
}
.form-contact input,
.form-contact textarea {
  background-color: var(--primaryBackgroundColor);
  margin-bottom: 1rem;
  width: 100%;
  border-radius: 0.3125rem;
  border: 1px solid var(--primaryColorNeutral100);
  padding: 0 0.75rem;
  font-family: var(--font);
  color: var(--tertiaryColor);
}

.form-contact-dark input,
.form-contact-dark textarea {
  background: linear-gradient(#222, var(--primaryColorBlack));
}

.form-contact input {
  height: 2.9375rem;
}
.form-contact textarea {
  height: 12.5rem;
  padding-top: 0.88rem;
  resize: none;
}
.form-contact textarea::placeholder {
  font-family: var(--font);
}
.form-contact button {
  margin-top: 1.5rem;
  margin-left: auto;
}

.right img {
  margin-bottom: 1.63rem;
}

.email,
.phone {
  max-width: 25rem;
  width: 100%;
  height: 4.375rem;
  border-left: 4px solid var(--primaryColor);
  color: var(--tertiaryColor);
  margin-bottom: 1.31rem;
  padding-left: 1rem;
  margin-left: 1.5rem;
}

.email p:nth-child(1),
.phone p:nth-child(1) {
  font-weight: 600;
}

.email p:nth-child(2),
.phone p:nth-child(2) {
  font-size: 1.5rem;
  font-weight: 400;
}

@media screen and (max-width: 1024px) { 
  .contact-form{
    overflow: hidden;
  padding-bottom: 10rem;

  }

  .contact-form-content{
    flex-direction: column-reverse;
    align-items: center;
    gap: 4rem;
    padding: 0 1rem;
  }
  
}


