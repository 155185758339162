.who-we-are {
  min-height: 54.125rem;
  padding-bottom: 10rem;
}

.who-we-are-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
}

.about {
  margin: 2.05rem 0 6.01rem;
  text-align: center;
  max-width: 37.5rem;
  color: var(--bodyText);
}

.profile-container {
  position: relative;
  width: 19.60794rem;
  height: 19.25rem;
  margin-bottom: 2.05rem;
}

.profile-container img,
.profile-container-background {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.profile-container img {
  position: absolute;
  width: 18.38038rem;
  height: 18.625rem;
}

.profile-container-background {
  position: absolute;
  width: 19.60794rem;
  height: 18.625rem;
  flex-shrink: 0;
}

.who-we-are-content h3 {
  color: var(--headerText);
  text-align: center;
  margin-bottom: 0.75rem;
}

.who-we-are-content h4 {
  color: var(--primaryColorNeutral100);
  font-weight: 600;
  letter-spacing: 0.1rem;
}
