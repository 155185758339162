.service-updates {
  min-height: 54.125rem;
  z-index: 2;
  margin-bottom: 10rem;
}

.service-updates-content {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  gap: 2rem;
  padding: 1rem 0;
}

.left {
  max-width: 20.125rem;
  width: 100%;
}

.left p {
  padding-bottom: 3.125rem;
}

.service-updates-content h2 {
  color: var(--tertiaryColor);
  margin-bottom: 1.25rem;
  font-weight: 700;
  font-size: 2rem;
}

.service-updates-content span {
  color: var(--primaryColor);
  font-weight: 600;
}

.service-updates-content p {
  color: var(--bodyText);
}

.right {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 2.5rem;
}

.card {
  border-radius: 0.5rem;
  max-width: 17.5rem;
  width: 100%;
  height: 22.664375rem;
  padding: 1.875rem;
  border-top: 4px solid var(--primaryColor);
  box-shadow: 0px 4px 25px 10px rgba(0, 0, 0, 0.08);
}

.card-dark {
  background-color: #1b1b1b;
  border-bottom: 1px solid var(--primaryColor);
  box-shadow: 0 0 15px -5px var(--primaryColor);
  border-right: 1px solid var(--primaryColor);
  border-top: 4px solid var(--primaryColor);
  border-left: 1px solid var(--primaryColor);
}

.card:nth-child(2) {
  margin-top: 2.5rem;
}

.card:nth-child(4) {
  margin-top: 2.5rem;
}

.card h3 {
  color: var(--tertiaryColor);
  font-weight: 700;
}

.right .icon-container {
  width: 50px;
  height: 50px;
  margin-bottom: 1.25rem;
}

.right .icon-container img {
  width: 100%;
  height: 100%;
}

.card h3 {
  color: var(--primaryColor);
  margin-bottom: 0.9375rem;
}

@media screen and (max-width: 1024px) {
  .left {
    max-width: none;
    margin-bottom: 3rem;
  }

  .service-updates-content {
    flex-direction: column;
  }

  .right {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    column-gap: 2.5rem;
    row-gap: 2.5rem;
  }

  .card:nth-child(2) {
    margin-top: 0;
  }

  .card:nth-child(4) {
    margin-top: 0;
  }
}
