.payment-plan {
  min-height: 48.8125rem;
  padding-bottom: 10rem;
}

.payment-plan-content {
  display: flex;
  height: 100%;
  justify-content: space-between;
}

.content-left img {
  width: 100%;
  margin-top: 21.31rem;
}

.content-right {
  max-width: 38.8125rem;
}

.content-right h2 {
  color: var(--tertiaryColor);
  font-weight: 600 !important;
  margin-bottom: 0.75rem;
}

.content-right span {
  color: var(--primaryColor);
}

.content-right .desc {
  color: var(--bodyText);
  margin-bottom: 3.25rem;
}

.perks {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  column-gap: 3.25rem;
  row-gap: 2rem;
}

.perk {
  display: flex;
  gap: 0.5rem;
}

.perk h3 {
  white-space: nowrap;
  margin: 0.12rem 0 0.5rem;
  color: var(--headerText);
}

.perk p{
  color: var(--bodyText);
}

@media screen and (max-width: 1024px) {
  .payment-plan-content{
    flex-direction: column;
    align-items: start;
  }

  .content-left img {
    margin-top: 0;
    margin-bottom: 2rem;
    height: 16.40625rem;
  }

}

@media screen and (max-width: 768px) {
  .content-right .desc{
    max-width: 23.8125rem;
  }

  .perks{
    display: grid;
  grid-template-columns: 1fr; 
  grid-template-rows: repeat(6, 1fr); 
  column-gap: 0; 
  }

  .perk{
    max-width: 18rem;
    width: 100%;
  }
}
