header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.hero {
  position: relative;
  height: 56.25rem;
  width: 100%;
  padding-bottom: 4.5rem;
  background: linear-gradient(
    301deg,
    var(--primaryColor) 34.39%,
    var(--primaryColorGreen200) 100%
  );
}

.hero-dark {
  background: linear-gradient(
    301deg,
    var(--primaryColorGreen100) 34.39%,
    var(--primaryColorGreen200) 100%
  );
}

.background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 1; /* Ensure container is the base layer */
}

.background-piece-one-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.background-container-dark {
  background: rgba(0, 40, 60, 0.3);
}

.hero-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 11.6875rem;
  max-width: 31.5625rem;
  width: 100%;
}

.hero-content h1,
.hero-content h2 {
  color: var(--secondaryColor);
}

.hero-content h2{
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}

.hero-image {
  position: absolute;
  width: 42.6875rem;
  height: 38.625rem;
  right: 0;
  top: 0;
  margin-top: 13.1875rem;
  z-index: -1;
  user-select: none;
}

.hero-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1024px) {
  .hero-content {
    max-width: 30.5625rem;
  }

  .hero-image {
    width: 34.15rem;
    height: 30.9rem;
    right: 2.5rem;
    margin-top: 25.1875rem;
  }
}

@media screen and (max-width: 768px) {
  .hero-image {
    position: absolute;
    width: 23.905rem;
    height: 21.63rem;
    right: 2.5rem;
    top: 0;
    margin-top: 32.6875rem;
  }
}
