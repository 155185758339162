.customer-plan-options {
  margin: 0;
  height: 50.5rem;
}

.background-blend{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--primaryBackgroundColor);
  z-index: -1;
}

.customer-plan-options-content {
  height: 100%;
}

.plan-options {
  display: flex;
  justify-content: center;
  height: 100%;
  gap: 1.7rem;
}

.plan {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  width: 18.75rem;
  height: 31.25rem;
  padding: 0 2.19rem;
  border: 1px solid var(--primaryColorNeutral600);
  border-radius: 4px;
  background: white;
  margin-top: 9.62rem;
}

.plan-dark {
  background: #1b1b1b;
  box-shadow: 0 0 25px -5px var(--primaryColor);
  border: 1px solid var(--primaryColor);
}

.plan:nth-child(1) {
}

.plan:nth-child(2) {
  transform: scale(1.2);
  z-index: 5;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);

}

.plan-dark:nth-child(2){
  box-shadow: 0px 0px 15px 0px var(--primaryColor);

}



.plan:nth-child(3) {
}

.plan-title-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 3.125rem;
  border-radius: 3.9px 3.9px 0 0;
  border: 1px solid var(--primaryColorNeutral600);
  background: var(--primaryColorNeutral900);
}

.plan:nth-child(2) .plan-title-container {
  background: var(--bannerColor);
}

.plan-title-container-dark {
  border: 0;
  border-bottom: 2px solid var(--primaryColorNeutral600);

  background-color: #000;
}

.plan-title-container h2 {
  color: var(--planCardText);
  text-align: center;
  font-size: 0.875rem;
  font-weight: 600;
}

.plan span {
  color: var(--planCardText);
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
  margin: 4.625rem 0 0.25rem;
}

.price {
  display: flex;
  height: 4rem;
  margin: 1.12rem 0 1.87rem;
}

.money-sign,
.per-month {
  color: var(--primaryColor);
  font-weight: 300;
}

.money-sign {
  font-size: 1.75rem;
  align-self: start;
}

.per-month {
  font-size: 1.25rem;
  align-self: end;
}

.dollar-amount {
  color: var(--primaryColor);
  font-size: 4rem;
  font-weight: 300;
  align-self: center;
}

.perk {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.perk:last-child {
  margin-bottom: 0;
}

.perk img {
  width: 0.9375rem;
  height: 0.9375rem;
}

.perk li {
  color: var(--planCardText);
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7rem;
  width: 200px;
}

.plan button {
  margin-top: 1.44rem;
}

@media screen and (max-width: 1024px) {
  .customer-plan-options {
    height: auto;
    background-color: none;
  }

  .plan-options {
    display: flex;
    flex-direction: column;
    align-items: center;
  margin-bottom: 14.43rem;

  }

  .plan {
    padding: 0 2.19rem;
    border: 1px solid var(--primaryColorNeutral600);
    border-radius: 4px;
    margin-top: 1rem;
  }

  .plan:nth-child(2) {
    transform: scale(1);
    z-index: none;
    box-shadow: none;

  }

  .plan-dark:nth-child(2){
    box-shadow: 0px 0px 15px 0px var(--primaryColor);
  
  }

  .plan:nth-child(2) .plan-title-container {
  background: var(--primaryColorNeutral900);

  }

  .plan-dark:nth-child(2) .plan-title-container{
  background-color: #000;

  }
}
