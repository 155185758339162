.space-content {
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
  height: 25rem;
}

.universe-one {
  height: 50%;
  width: 100%;
  left: 0;
}

.universe-two {
  position: absolute;
  width: 100%;
  height: 40%;
  left: 0 !important;
  top: 1% !important;
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
}

.universe-three {
  position: absolute;
  width: 100%;
  height: 50%;
  left: 10% !important;
  top: 0 !important;
  -ms-transform: scaleY(-1);
  transform: scaleY(-1);
}

.universe-one .shooting-star {
  position: absolute;
  right: 50%;
  top: -13.59%;
  width: 1px;
  height: 70px;
  background: #fff;
  transform: rotate(-52deg);
  animation: shootingStar;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
  animation-delay: 1s;
}

@keyframes shootingStar {
  0% {
    top: -13.59%;
    right: 60%;
  }

  2% {
    opacity: 0;
    top: 100%;
    right: 0;
  }

  86% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.universe-one .star2 {
  top: -23.59%;
  animation: shootingStar2;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
  animation-delay: 1.5s;
}

@keyframes shootingStar2 {
  0% {
    top: -23.59%;
    right: 50%;
  }

  2% {
    opacity: 0;
    top: 100%;
    right: -17%;
  }

  86% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.satellite {
  width: 10px !important;
  position: absolute;
  top: 10%;
  left: 0;
  animation-name: orbit;
  animation-duration: 11s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.universe-two .satellite {
  animation-delay: 10s;
  left: -10%;
}

.universe-three .satellite {
  animation-delay: 25s;
  left: -15%;
}

@keyframes orbit {
  0% {
    top: 10%;
    left: 0;
    transform: rotate(0);
  }

  100% {
    top: -40%;
    left: 120%;
    transform: rotate(360deg);
  }
}

.star {
  position: absolute;
  opacity: 0.5;
}

.universe .star:nth-child(4) {
  top: 17%;
  left: 5%;
  animation: starFlicker;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.8s;
}

.universe-one .star:nth-child(5) {
  top: 11%;
  right: 19%;
  animation: starFlicker;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.2s;
}

.universe-one .star:nth-child(6) {
  top: 4%;
  right: 24%;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.9s;
}

.universe-one .star:nth-child(7) {
  top: 3%;
  left: 29%;
  animation: starFlicker;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 1.2s;
}

.universe-one .star:nth-child(8) {
  top: 18%;
  left: 56%;
}

.universe-one .star:nth-child(9) {
  top: 31%;
  left: 57%;
  animation: starFlicker;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.1s;
}

.universe-one .star:nth-child(10) {
  top: 27%;
  left: 12%;
  animation: starFlicker;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.2s;
}

.universe-one .star:nth-child(11) {
  top: 7%;
  right: 31%;
  animation: starFlicker;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 1.4s;
}

.universe-one .star:nth-child(12) {
  top: 35%;
  right: 12%;
  animation: starFlicker;
  animation-duration: 4.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 1.1s;
}

.universe-one .star:nth-child(13) {
  top: 12%;
  right: 25%;
  animation: starFlicker;
  animation-duration: 3.3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.7s;
}

.universe-two .star:nth-child(2) {
  top: 1.3%;
  left: 48%;
  animation: starFlicker;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
}

.universe-two .star:nth-child(3) {
  top: 20%;
  left: 36%;
  animation: starFlicker;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}

.universe-two .star:nth-child(4) {
  top: 17%;
  left: 5%;
  animation: starFlicker;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.8s;
}

.universe-two .star:nth-child(5) {
  top: 11%;
  right: 19%;
  animation: starFlicker;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.2s;
}

.universe-two .star:nth-child(6) {
  top: 4%;
  right: 24%;
  animation: starFlicker;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.9s;
}

.universe-two .star:nth-child(7) {
  top: 3%;
  left: 29%;
  animation: starFlicker;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 1.2s;
}

.universe-two .star:nth-child(8) {
  top: 18%;
  left: 56%;
  animation: starFlicker;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.4s;
}

.universe-two .star:nth-child(9) {
  top: 31%;
  left: 57%;
  animation: starFlicker;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.1s;
}

.universe-two .star:nth-child(10) {
  top: 27%;
  left: 12%;
  animation: starFlicker;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.2s;
}

.universe-two .star:nth-child(11) {
  top: 7%;
  right: 31%;
  animation: starFlicker;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 1.4s;
}

.universe-two .star:nth-child(12) {
  top: 35%;
  right: 12%;
  animation: starFlicker;
  animation-duration: 4.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 1.1s;
}

.universe-two .star:nth-child(13) {
  top: 12%;
  right: 25%;
  animation: starFlicker;
  animation-duration: 3.3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.7s;
}

.universe-two .star:nth-child(7) {
  top: -10% !important;
}

.universe-two .star:nth-child(2) {
  top: -15% !important;
}

.universe-three .star:nth-child(2) {
  top: 1.3%;
  left: 48%;
  animation: starFlicker;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
}

.universe-three .star:nth-child(3) {
  top: 20%;
  left: 36%;
  animation: starFlicker;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}

.universe-three .star:nth-child(4) {
  top: 17%;
  left: 5%;
  animation: starFlicker;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.8s;
}

.universe-three .star:nth-child(5) {
  top: 11%;
  right: 19%;
  animation: starFlicker;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.2s;
}

.universe-three .star:nth-child(6) {
  top: 4%;
  right: 24%;
  animation: starFlicker;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.9s;
}

.universe-three .star:nth-child(7) {
  top: 3%;
  left: 29%;
  animation: starFlicker;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 1.2s;
}

.universe-three .star:nth-child(8) {
  top: 18%;
  left: 56%;
  animation: starFlicker;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.4s;
}

.universe-three .star:nth-child(9) {
  top: 31%;
  left: 57%;
  animation: starFlicker;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.1s;
}

.universe-three .star:nth-child(10) {
  top: 27%;
  left: 12%;
  animation: starFlicker;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.2s;
}

.universe-three .star:nth-child(11) {
  top: 7%;
  right: 31%;
  animation: starFlicker;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 1.4s;
}

.universe-three .star:nth-child(12) {
  top: 35%;
  right: 12%;
  animation: starFlicker;
  animation-duration: 4.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 1.1s;
}

.universe-three .star:nth-child(13) {
  top: 12%;
  right: 25%;
  animation: starFlicker;
  animation-duration: 3.3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.7s;
}

@keyframes starFlicker {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 0.2;
  }
}

@media screen and (max-width: 1024px) {
  .universe-one {
    height: 55%;
  }

  .universe-two {
    height: 45%;
  }

  .universe-three {
    height: 55%;
  }

  .universe-one .shooting-star {
    transform: rotate(-42deg);
  }
}

@media screen and (max-width: 768px) {
  .universe-one {
    height: 55%;
  }

  .universe-two {
    height: 45%;
  }

  .universe-three {
    height: 55%;
  }

  .universe-one .shooting-star {
    position: absolute;
    right: 90%;
    top: -13.59%;
    width: 1px;
    height: 70px;
    background: #fff;
    transform: rotate(-50deg);
    animation: shootingStar;
    animation-duration: 9s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;
    animation-delay: 1s;
  }

  @keyframes shootingStar {
    0% {
      top: -13.59%;
      right: 90%;
    }

    2% {
      opacity: 0;
      top: 100%;
      right: 0;
    }

    86% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  .universe-one .star2 {
    top: -23.59%;
    animation: shootingStar2;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;
    animation-delay: 1.5s;
  }

  @keyframes shootingStar2 {
    0% {
      top: -23.59%;
      right: 90%;
    }

    2% {
      opacity: 0;
      top: 100%;
      right: -17%;
    }

    86% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }
}

@media screen and (max-width: 425px) {
  .universe-one .shooting-star {
    position: absolute;
    right: 90%;
    top: -13.59%;
    width: 1px;
    height: 70px;
    background: #fff;
    transform: rotate(-38deg);
    animation: shootingStar;
    animation-duration: 9s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;
    animation-delay: 1s;
  }
}
